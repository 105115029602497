<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            'name',
            getHelperObject('identification_type'),
            'identification',
            { key: '_phones', label: 'Phones' },
            { key: '_emails', label: 'Emails' },
            { key: '_buildings', label: 'Buildings' }
          ]"
          :config="{
            url: 'admin/building_contacts',
            route: '/admin/property/building/contacts',
            name: 'contact',
            lname: 'building_contacts',
            params: { _with: 'phones,emails,buildings.type,buildings.unit', _lists: 'contact_types,identification_types' },
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        >
          <template #_phones="{item}">
            <td>
              <div v-for="(phone, index) of item._phones.split(',')" :key="'P' + index">{{ phone }}</div>
            </td>
          </template>
          <template #_emails="{item}">
            <td>
              <div v-for="(email, index) of item._emails.split(',')" :key="'E' + index">{{ email }}</div>
            </td>
          </template>
          <template #_buildings="{item}">
            <td>
              <div v-for="(building, index) of item._buildings.split(',')" :key="'B' + index">{{ building }}</div>
            </td>
          </template>
        </ACRUDTable>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Contacts',
  mixins: [crudMixin],
  methods: {
    parseItems(parent, items) {
      return items.map(item => {
        item._phones = []
        item._emails = []
        item._buildings = []
        item.phones.forEach(phone => item._phones.push(phone.phone))
        item.emails.forEach(email => item._emails.push(email.email))
        item.buildings.forEach(building => item._buildings.push(this.parseBuilding(building)))
        item._phones = item._phones.join(',')
        item._emails = item._emails.join(',')
        item._buildings = item._buildings.join(',')
        return item
      })
    },

    // TODO: Move to template ? or Global function
    parseBuilding(building) {
      const contact = this.view_helpers.contact_types[building.pivot.type_id] || {}
      return this.parsers.buildingFull(building) + `- [${contact.name || '--'}]`
    }
  }
}
</script>
