var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          'name',
          _vm.getHelperObject('identification_type'),
          'identification',
          { key: '_phones', label: 'Phones' },
          { key: '_emails', label: 'Emails' },
          { key: '_buildings', label: 'Buildings' }
        ],"config":{
          url: 'admin/building_contacts',
          route: '/admin/property/building/contacts',
          name: 'contact',
          lname: 'building_contacts',
          params: { _with: 'phones,emails,buildings.type,buildings.unit', _lists: 'contact_types,identification_types' },
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([{key:"_phones",fn:function(ref){
        var item = ref.item;
return [_c('td',_vm._l((item._phones.split(',')),function(phone,index){return _c('div',{key:'P' + index},[_vm._v(_vm._s(phone))])}),0)]}},{key:"_emails",fn:function(ref){
        var item = ref.item;
return [_c('td',_vm._l((item._emails.split(',')),function(email,index){return _c('div',{key:'E' + index},[_vm._v(_vm._s(email))])}),0)]}},{key:"_buildings",fn:function(ref){
        var item = ref.item;
return [_c('td',_vm._l((item._buildings.split(',')),function(building,index){return _c('div',{key:'B' + index},[_vm._v(_vm._s(building))])}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }